import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'autograph',
    meta: {
      title: '跨境商品销售推广服务协议',
    },
    component: () => import('@/views/autograph.vue')
  },
  {
    path: '/detail',
    name: 'product_detail',
    meta: {
      title: '商品详情',
    },
    component: () => import('@/views/detail.vue')
  },
  {
    path: '/logon',
    name: 'logon_detail',
    meta: {
      title: '注册',
    },
    component: () => import('@/views/logon.vue')
  },
  {
    path: '/draw',
    name: 'draw',
    meta: {
      title: '防伪码抽奖',
    },
    component: () => import('@/views/draw.vue')
  },
  {
    path: '/query',
    name: 'query_detail',
    meta: {
      title: '中奖查询',
    },
    component: () => import('@/views/query.vue')
  },
  {
    path: '/receive',
    name: 'user_receive',
    meta: {
      title: '奖品领取',
    },
    component: () => import('@/views/receive.vue')
  },
  {
    path: '/draw/hy',
    meta: {
      title: '运单号抽奖',
    },
    component: () => import('@/views/hyDraw.vue')
  },
  {
    path: '/receive/hy',
    meta: {
      title: '荒野奖品领取',
    },
    component: () => import('@/views/hyReceive.vue')
  },
  {
    path: '/material',
    meta: {
      title: '活动物料',
    },
    component: () => import('@/views/material.vue')
  },
  {
    path: '/act',
    meta: {
      title: '限时特惠',
    },
    component: () => import('@/views/act.vue')
  },
  {
    path: '/query/hy',
    meta: {
      title: '荒野中奖查询',
    },
    component: () => import('@/views/hyQuery.vue')
  },
  {
    path: '/security/hy',
    meta: {
      title: '鸿业防伪码查询',
    },
    component: () => import('@/views/hySecurity.vue')
  },
  {
    path: '/security/sy',
    meta: {
      title: '晟煜防伪码查询',
    },
    component: () => import('@/views/sySecurity.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',

  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
    next()
  }
})

export default router